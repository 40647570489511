import { Button } from "@mui/material";
import { useState } from "react";
import { BlogForm } from "./BlogForm";
import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";
import { PreviewBlog } from "./PreviewBlog";
import { withFormik } from "../../utils/Helpers";
import { FormikValues } from "formik";

export interface AddBlogProps {
  initialValues: FormikValues;
  submitHandler: (values: unknown) => Promise<void>;
  validationHandler: (values: unknown) => unknown;
}

const AddBlogMain = (props: AddBlogProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}>Add Blog</Button>
      <FullScreenDialog handleClose={() => setOpen(false)} open={open} title={"Add Blog"}>
        <FormPageLayout
          formComponent={<BlogForm />}
          previewComponent={<PreviewBlog />} />
      </FullScreenDialog >
    </>
  );
};

export const AddBlog = withFormik(AddBlogMain);