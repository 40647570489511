import { BlogForm, IBlogForm } from "./BlogForm";
import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";
import { PreviewBlog } from "./PreviewBlog";
import React from "react";
import { IEditMode } from "../../Types";
import axios from "axios";
import { ImageType } from "../ImageItem";
import { useFormikContext } from "formik";
import { withFormik } from "../../utils/Helpers";

export interface EditBlogProps {
  initialValues: IBlogForm;
  submitHandler: (values: unknown) => Promise<void>;
  validationHandler: (values: unknown) => unknown;
  editMode: IEditMode;
  onClose: () => void;
}

const EditBlogMain = (props: EditBlogProps) => {
  const { setValues } = useFormikContext<IBlogForm>();

  React.useEffect(() => {
    if (!props.editMode.id)
      return;
    
    axios.get(`${process.env.REACT_APP_BASE_URL}/blog/${props.editMode.id}`)
    .then((response) => {
      if (response.data) {
        const formData = response.data;
        setValues({
          title: formData.title,
          featured: formData.featured,
          content: formData.content,
          thumbnail: {
            filePath: formData.thumbnail,
          } as ImageType
        });
      }
    }).catch(() => {
      console.log("Error fetching blog with id", props.editMode.id);
    });
  }, [props.editMode, setValues]);

  return (
    <FullScreenDialog handleClose={props.onClose} open={props.editMode.isOpen} title={"Update Blog"}>
      <FormPageLayout
        formComponent={<BlogForm />}
        previewComponent={<PreviewBlog />} />
    </FullScreenDialog >
  );
};

export const EditBlog = withFormik(EditBlogMain);