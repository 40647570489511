import { PreviewContainer } from "../PreviewContainer/PreviewContainer";
import formPageLayout from "./FormPageLayout.module.scss";

export interface IFormPageLayout {
  formComponent: React.ReactNode;
  previewComponent: React.ReactNode;
}

export const FormPageLayout = ({ previewComponent, formComponent }: IFormPageLayout) => {
  return (
    <div className={formPageLayout.container}>
      <div className={`${formPageLayout.widthHalfPage} ${formPageLayout.formContainer}`}>
        {formComponent}
      </div>
      <div className={formPageLayout.widthHalfPage}>
        <PreviewContainer>
          {previewComponent}
        </PreviewContainer>
      </div>
    </div>
  );
};
