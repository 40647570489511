import React from "react";
import axios from "axios";
import { PageContentLayout } from "../components/home/PageContentLayout";
import { drawerTabs } from "./home/HomeData";
import { IEditMode, IProject, TableContent } from "../Types";
import { Loading } from "../components/loading/Loading";
import { convertDateFormat } from "../utils/Utils";
import { AddProject } from "../components/project/AddProject";
import { INITIAL_VALUE, IProjectForm } from "../components/project/ProjectForm";
import { deleteHandler, submitHandler, submitUpdateHandler, validationHandler } from "../components/project/FormHandler";
import { EditProject } from "../components/project/EditProject";

export const Projects = () => {
    const [content, setContent] = React.useState<TableContent>({
        columns: [],
        data: [],
    });
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>("");
    const [editMode, setEditMode] = React.useState<IEditMode>({ isOpen: false });
    const editModeProject = React.useRef<IProjectForm>();

    const createContent = React.useCallback((data: IProject[]) => {
        const content: TableContent = {
            columns: [],
            data: [],
            isEditable: true,
            onEditClick: (id: string) => setEditMode({ isOpen: true, id }),
            onDeleteClick: (id: string) => deleteHandler(id),
        };

        // Moving cover as first property and removing projectId
        // Calculating columns
        content.columns = Object.keys(data[0]).filter((val) => val !== "cover").map((val) => val === "projectId" ? "cover" : val);

        // Calculating data
        data.forEach((item: IProject) => {
            item.addedDate = convertDateFormat(item.addedDate);
            const { projectId, cover, ...rest } = item;
            const updatedItem = { projectId, cover, ...rest };
            content.data.push(Object.values(updatedItem));
        });

        setContent(content);
    }, []);

    React.useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_BASE_URL}/projects`)
            .then((response) => {
                createContent(response.data);
                setLoading(false);
            }).catch(() => {
                setError("Error fetching projects");
                setLoading(false);
            });
    }, [createContent]);

    if (isLoading)
        return <Loading />;

    if (error)
        return <>{error}</>;

    return (
        <PageContentLayout
            contentType={drawerTabs[1]}
            tableContent={content!}
        >
            <AddProject initialValues={INITIAL_VALUE} submitHandler={submitHandler} validationHandler={validationHandler} />
            <EditProject
                initialValues={INITIAL_VALUE}
                originalProjectRef={editModeProject}
                submitHandler={(values: any) => submitUpdateHandler({ ...values, projectId: editMode.id }, editModeProject.current?.images ?? [])}
                validationHandler={validationHandler}
                editMode={editMode}
                onClose={() => setEditMode({ isOpen: false })}
            />
        </PageContentLayout>
    );
};
