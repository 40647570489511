import { useCallback } from 'react';
import { Image, UploadImage } from './ImageItem';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useFormikContext } from 'formik';

export const UploadImages = () => {
  const { values, setFieldValue } = useFormikContext<"images">();

  const onAdd = useCallback((file: File) => {
    const imageFile: Image = {
      fileName: file.name,
      filePath: URL.createObjectURL(file),
      file
    }
    const newList = [...(values["images" as any] as any), imageFile];
    setFieldValue("images", newList);
  }, [values, setFieldValue]);


  return (
    <>
      <Button variant="outlined" component="label" >
        <AddIcon /> Add Image
        <input hidden type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            onAdd(e.target.files[0]);
          }
        }} />
      </Button>
      <div style={{ display: "flex" }}>
        {
          (values["images" as any] as any).map((element: any) => {
            return <UploadImage image={element} />
          })
        }
      </div>
    </>
  )
};
