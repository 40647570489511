import React from "react";
import { Typography } from "@mui/material";
import { Button } from '@mui/material';
import { Field, Form, useFormikContext } from "formik";
import { Checkbox, TextField } from 'formik-mui';
import { DescriptionBox } from "../DescriptionBox/DescrptionBox";
import addBlogStyle from "./AddBlog.module.scss";
import { UploadImage, Image, ImageType } from '../ImageItem';

export interface IBlogForm {
  title: string;
  featured: boolean;
  content: string;
  thumbnail: ImageType;
}

export const INITIAL_VALUE: IBlogForm = {
  title: "",
  featured: false,
  content: "",
  thumbnail: undefined as ImageType,
}

export const BlogForm = () => {
  const { values, isSubmitting, submitForm, setFieldValue } = useFormikContext<IBlogForm>();

  return (
    <Form className={addBlogStyle.form}>
      <Typography variant="body1">Blog thumbnail</Typography>
      <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
        if (e.target.files && e.target.files[0]) {
          const file = e.target.files[0];
          const imageFile: Image = {
            fileName: file.name,
            filePath: URL.createObjectURL(file),
            file
          }
          setFieldValue("thumbnail", imageFile);
        }
      }} />
      {values.thumbnail !== undefined && (<UploadImage image={values.thumbnail} />)}

      <Field
        className={`${addBlogStyle.spacing}`}
        component={TextField}
        name="title"
        value={values.title}
        label="Title"
        variant="outlined"
        size="small"
        fullWidth
      />
      <Field
        className={`${addBlogStyle.spacing}`}
        component={Checkbox}
        name="featured"
        value={Boolean(values.featured)}
        label="Featured"
        variant="outlined"
        size="small"
        fullWidth
      /> Featured
      <DescriptionBox name={"content"} value={values.content} />
      <Button
        className={addBlogStyle.spacing}
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={submitForm}
      >
        Post
      </Button>
    </Form>
  );
};
