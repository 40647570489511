import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React from "react";
import styles from "./HorizontalTabs.module.scss";

export interface HorizontalTabsProps {
  tabs: string[];
}

export const HorizontalTabs = (props: React.PropsWithChildren<HorizontalTabsProps>) => {
  const [value, setValue] = React.useState(props.tabs[0]);

  // This event is React.synthetic event which exist in React 18 but not in 17
  const handleChange = (_event: any, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box className={styles.box}>
        <TabList onChange={handleChange}>
          {props.tabs.map((tab) => <Tab label={tab} value={tab} />)}
        </TabList>
      </Box>
      <TabPanel value={value}>{props.children}</TabPanel>
    </TabContext>
  );
};
