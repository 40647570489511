import { Box, Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";

export interface DrawerProps {
  activeTab: string;
  tabs: string[];
  className?: string;
  onClick: (selected: string) => void;
}

export const DrawerComponent = (props: DrawerProps) => {
  const list = React.useMemo(() => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    >
      <List>
        {props.tabs.map((text) => (
          <ListItem key={text} disablePadding onClick={() => props.onClick(text)}>
            <ListItemButton selected={props.activeTab === text}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  ), [props]);

  return (
    <Drawer variant="permanent" className={props.className}>
      {list}
    </Drawer>
  );
}