import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";
import React from "react";
import { IEditMode } from "../../Types";
import axios from "axios";
import { ImageType } from "../ImageItem";
import { useFormikContext } from "formik";
import { withFormik } from "../../utils/Helpers";
import { IProfileForm, ProfileForm } from "./ProfileForm";
import { PreviewProfile } from "./PreviewProfile";

export interface EditProfileProps {
  initialValues: IProfileForm;
  submitHandler: (values: unknown) => Promise<void>;
  validationHandler: (values: unknown) => unknown;
  editMode: IEditMode;
  onClose: () => void;
}

const EditProfileMain = (props: EditProfileProps) => {
  const { setValues } = useFormikContext<IProfileForm>();

  React.useEffect(() => {
    if (!props.editMode.id)
      return;
    
    axios.get(`${process.env.REACT_APP_BASE_URL}/profile/${props.editMode.id}`)
    .then((response) => {
      if (response.data) {
        const formData = response.data;
        setValues({
          name: formData.name,
          role: formData.role,
          description: formData.description,
          image: formData.thumbnail,
          sidePose: {
            filePath: formData.sidePose,
          } as ImageType,
        });
      }
    }).catch(() => {
      console.log("Error fetching profile with id", props.editMode.id);
    });
  }, [props.editMode, setValues]);

  return (
    <FullScreenDialog handleClose={props.onClose} open={props.editMode.isOpen} title={"Update Profile"}>
      <FormPageLayout
        formComponent={<ProfileForm />}
        previewComponent={<PreviewProfile />} />
    </FullScreenDialog >
  );
};

export const EditProfile = withFormik(EditProfileMain);