import { Image } from "../ImageItem";

export interface Project {
  location: string;
  capability: string;
  projectType: string;
  description: string;
  images: Image[];
};

export const capability = [
  "Planning",
  "Certificate of Lawfulness",
  "Building Regulations",
  "Construction Management",
  "Consultancy",
];

export const projectType = [
  "Rear Extension",
  "Side Extension",
  "House Extension",
  "Porch Enlargement",
  "Roof Alteration",
  "Loft Conversion",
  "Front extension",
  "Front extension",
  "Single-Storey Rear Extension",
  "Rear and Front Extension",
  "Rear and Side Extension",
  "Rear Extension & Front Porch",
  "Rear Extension & Loft Conversion",
  "Side Extension & Garden Outbuilding",
  "Part Garage conversion & Rear extension",
  "Garage Conversion & Single Storey Extension",
  "Porch enlargement & facade alterations",
  "Single Storey side & rear extension",
  "Single storey rear & single storey front extension",
  "Part Garage conversion & Rear extension",
  "Double storey side extension & part first floor extension",
  "Single Storey side, rear extension & Part first floor extension",
  "Ground floor rear extension, First floor side extension & porch",
];

export interface ProjectWithId extends Project {
  projectId: string;
}