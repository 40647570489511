import React from "react";
import axios from "axios";
import { Loading } from "../components/loading/Loading";
import { IEditMode, IProfile, TableContent } from "../Types";
import { PageContentLayout } from "../components/home/PageContentLayout";
import { drawerTabs } from "./home/HomeData";
import { AddProfile } from "../components/Profile/AddProfile";
import { INITIAL_VALUE } from "../components/Profile/ProfileForm";
import { deleteHandler, submitHandler, submitUpdateHandler, validationHandler } from "../components/Profile/FormHandler";
import { EditProfile } from "../components/Profile/EditProfile";

export const Profiles = () => {
  const [content, setContent] = React.useState<TableContent>({
    columns: [],
    data: [],
  });
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [editMode, setEditMode] = React.useState<IEditMode>({ isOpen: false });

  const createContent = React.useCallback((data: IProfile[]) => {
    const content: TableContent = {
        columns: [],
        data: [],
        isEditable: true,
        onEditClick: (id: string) => setEditMode({ isOpen: true, id }),
        onDeleteClick: (id: string) => deleteHandler(id),
    };

    // Moving thumbnail as first property and removing profileId
    // Calculating columns
    content.columns = Object.keys(data[0]).filter((val) => val !== "thumbnail").map((val) => val === "profileId" ? "thumbnail" : val);

    // Calculating data
    data.forEach((item: IProfile) => {
        const { profileId, thumbnail, ...rest } = item;
        const updatedItem = { profileId, thumbnail, ...rest };
        content.data.push(Object.values(updatedItem));
    });

    setContent(content);
  }, []);

  React.useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_BASE_URL}/profiles`)
      .then((response) => {
        createContent(response.data);
        setLoading(false);
      }).catch(() => {
        setError("Error fetching profiles");
        setLoading(false);
      });
  }, [createContent]);

  if (isLoading)
    return <Loading />;

  if (error)
    return <>{error}</>;

  return (
    <PageContentLayout
      contentType={drawerTabs[3]}
      tableContent={content!}
    >
      <AddProfile initialValues={INITIAL_VALUE} submitHandler={submitHandler} validationHandler={validationHandler} />
      <EditProfile
        initialValues={INITIAL_VALUE}
        submitHandler={(values: any) => submitUpdateHandler({...values, profileId: editMode.id })}
        validationHandler={validationHandler}
        editMode={editMode}
        onClose={() => setEditMode({ isOpen: false })}
      />
    </PageContentLayout>
  );
};
