import React from "react";
import { horizontalTabs } from "../../pages/home/HomeData";
import styles from "./PageContentLayout.module.scss";
import { HorizontalTabs } from "../tabs/HorizontalTabs";
import { Table } from "../table/Table";
import { TableContent } from "../../Types";

export interface PageContentLayoutProps {
  contentType: string;
  tableContent: TableContent;
}

export const PageContentLayout = (props: React.PropsWithChildren<PageContentLayoutProps>) => {

  return (
    <div className={styles.homeContent}>
      <div className={styles.header}>
        {props.children}
      </div>
      <div className={styles.contentContainer}>
        <HorizontalTabs tabs={horizontalTabs}>
          <Table {...props.tableContent} />
        </HorizontalTabs>
      </div>
    </div>
  );
}