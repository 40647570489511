import { Button } from "@mui/material";
import { useState } from "react";
import { ProfileForm } from "./ProfileForm";
import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";
import { PreviewProfile } from "./PreviewProfile";
import { withFormik } from "../../utils/Helpers";
import { FormikValues } from "formik";

export interface AddProfileProps {
  initialValues: FormikValues;
  submitHandler: (values: unknown) => Promise<void>;
  validationHandler: (values: unknown) => unknown;
}


const AddProfileMain = (props: AddProfileProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}>Add Profile</Button>
      <FullScreenDialog handleClose={() => setOpen(false)} open={open} title={"Add Profile"}>
        <FormPageLayout
          formComponent={<ProfileForm />}
          previewComponent={<PreviewProfile />} />
      </FullScreenDialog >
    </>
  );
};

export const AddProfile = withFormik(AddProfileMain);
