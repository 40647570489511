import { Button } from "@mui/material";
import { useState } from "react";
import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";
import { ProjectForm } from './ProjectForm';
import { PreviewProject } from "./PreviewProject";
import { withFormik } from "../../utils/Helpers";
import { FormikValues } from "formik";

export interface AddProjectProps {
  initialValues: FormikValues;
  submitHandler: (values: unknown) => Promise<void>;
  validationHandler: (values: unknown) => unknown;
}

export const AddProjectMain = (props: AddProjectProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}>Add Project</Button>
      <FullScreenDialog handleClose={() => setOpen(false)} open={open} title={"Add Project"}>
        <FormPageLayout
          formComponent={<ProjectForm />}
          previewComponent={<PreviewProject />} />
      </FullScreenDialog>
    </>
  );
};

export const AddProject = withFormik(AddProjectMain);
