import React, { useState, useEffect, } from "react";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../graph";
import { loginRequest } from "../authConfig";

export interface IUser {
    displayName: string;
}

export interface IProfileLoader {
    children: React.ReactNode;
    onProfileLoaded: (profile: IUser) => void;
}


export const ProfileLoader = (props: IProfileLoader) => {
    const { instance, accounts } = useMsal();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => { props.onProfileLoaded(response); setLoaded(true) });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, instance, props.onProfileLoaded]);

    return (
        <>{loaded ? props.children : <p>loading...</p>}</>
    );
};
