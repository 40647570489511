import axios from "axios";
import { imageUploader } from "../../utils/ImageUplader";
import { Blog, BlogWithId } from "./Interface";
import { compressImage, CompressionLevel } from "../../utils/ImageCompressor";

export const validationHandler = (values: any) => {
  const errors: { [k: string]: any } = {};
  const requiredField = "Required";

  Object.keys(values).forEach((key) => {
    if (!values[key] || values[key]?.length === 0) {
      errors[key] = requiredField;
      return;
    }
  });
  return errors;
}

export const submitHandler = async (values: unknown) => {
  try {
    const { content, featured, thumbnail, title } = values as Blog;
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/save-blog`, {
      content,
      title,
      featured,
    });

    const blogId = response.data.blogId;
    const url = `${process.env.REACT_APP_BASE_URL}/save-blog-thumbnail/${blogId}`;
    const compressedCoverImage = await compressImage(thumbnail.file, CompressionLevel.Medium);
    await imageUploader(url, compressedCoverImage);
  } catch(err) {
    console.log("Error uploading blog", err);
  }
}

export const submitUpdateHandler = async (values: unknown) => {
  try {
    const { blogId, content, featured, thumbnail, title } = values as BlogWithId;
    await axios.post(`${process.env.REACT_APP_BASE_URL}/update-blog`, {
      blogId,
      updatedFields: {
        content,
        title,
        featured,
      },
    });

    if (!thumbnail)
      return;

    const url = `${process.env.REACT_APP_BASE_URL}/save-blog-thumbnail/${blogId}`;
    const compressedCoverImage = await compressImage(thumbnail.file, CompressionLevel.Medium);
    await imageUploader(url, compressedCoverImage);
  } catch(err) {
    console.log("Error updating blog", err);
  }
}

export const deleteHandler = async (id: string) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/remove-blog/${id}`);
  } catch(err) {
    console.log("Error deleting blog", err);
  }
}