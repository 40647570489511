import React, { ComponentType } from "react";
import { Formik, FormikValues } from "formik";

interface FormikProps {
  initialValues: FormikValues;
  submitHandler: (values: unknown) => Promise<void>;
  validationHandler: (values: unknown) => unknown;
}

export const withFormik = <P extends FormikProps>(Component: ComponentType<P>) => (props: P) => (
  <Formik
    initialValues={props.initialValues}
    onSubmit={async (values, { setSubmitting, setErrors }) => {
      const result = props.validationHandler(values);
      setErrors(result as any);
      await props.submitHandler(values as any);
      setSubmitting(false);
    }}>
    {() => (
      <Component {...props}/>
    )}
    </Formik>
)
