export interface Image {
  filePath: string,
  fileName: string,
  file: File
}
interface UploadImageProps {
  image: Image
}

export type ImageType = Image & undefined;
export const UploadImage = ({ image }: UploadImageProps) => {

  return (
    <div style={{ height: "120px", margin: "2px 4px" }}>
      <img src={image.filePath} style={{ maxHeight: "100px" }} alt="uploadImage" />
    </div>
  )
}