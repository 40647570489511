import React from "react";
import axios from "axios";
import { Loading } from "../components/loading/Loading";
import { IBlog, IEditMode, TableContent } from "../Types";
import { PageContentLayout } from "../components/home/PageContentLayout";
import { drawerTabs } from "./home/HomeData";
import { AddBlog } from "../components/Blog/AddBlog";
import { EditBlog } from "../components/Blog/EditBlog";
import { INITIAL_VALUE } from "../components/Blog/BlogForm";
import { deleteHandler, submitHandler, submitUpdateHandler, validationHandler } from "../components/Blog/FormHandler";

export const Blogs = () => {
  const [content, setContent] = React.useState<TableContent>({
    columns: [],
    data: [],
  });
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [editMode, setEditMode] = React.useState<IEditMode>({ isOpen: false });

  const createContent = React.useCallback((data: IBlog[]) => {
    const content: TableContent = {
        columns: [],
        data: [],
        isEditable: true,
        onEditClick: (id: string) => setEditMode({ isOpen: true, id }),
        onDeleteClick: (id: string) => deleteHandler(id),
    };

    // Moving thumbnail as first property and removing blogId
    // Calculating columns
    content.columns = Object.keys(data[0]).filter((val) => val !== "thumbnail").map((val) => val === "blogId" ? "thumbnail" : val);

    // Calculating data
    data.forEach((item: IBlog) => {
        const { blogId, thumbnail, ...rest } = item;
        const updatedItem = { blogId, thumbnail, ...rest };
        content.data.push(Object.values(updatedItem));
    });

    setContent(content);
  }, []);
  
  React.useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_BASE_URL}/blogs`)
    .then((response) => {
        if (response.data && response.data.length > 0) {
          createContent(response.data);
        } else {
          setError("No blogs found");
        }
        setLoading(false);
    }).catch(() => {
        setError("Error fetching blogs");
        setLoading(false);
    });
  }, [createContent]);

  if (isLoading)
    return <Loading />;

  if (error)
    return <>{error}</>;

  return (
    <PageContentLayout
      contentType={drawerTabs[2]}
      tableContent={content!}
    >
      <AddBlog initialValues={INITIAL_VALUE} submitHandler={submitHandler} validationHandler={validationHandler} />
      <EditBlog
        initialValues={INITIAL_VALUE}
        submitHandler={(values: any) => submitUpdateHandler({...values, blogId: editMode.id })}
        validationHandler={validationHandler}
        editMode={editMode}
        onClose={() => setEditMode({ isOpen: false })}
      />
    </PageContentLayout>
  );
};
