import React from "react";
import styles from "./PreviewBlog.module.scss";
import { IBlogForm } from "./BlogForm";
import parse from "html-react-parser";
import { Image } from "../ImageItem";
import { useFormikContext } from "formik";

export const PreviewBlog = () => {
  const { values } = useFormikContext<IBlogForm>();

  return (
    <div className={styles.container}>
      <p className={styles.date}>12 DECEMBER, 2022</p>
      <p className={styles.title}>{values.title}</p>
      {!values.thumbnail ? <></> : <img className={styles.thumbnail}
        src={(values.thumbnail as Image).filePath} alt="thumbnail" />}
      <div className={styles.content}>{(parse(values.content))}</div>
    </div>
  );
};
