import axios from "axios";
import { imageUploader } from "../../utils/ImageUplader";
import { Profile, ProfileWithId } from "./Interface";
import { CompressionLevel, compressImage } from "../../utils/ImageCompressor";

export const validationHandler = (values: any) => {
  const errors: { [k: string]: any } = {};
  const requiredField = "Required";

  Object.keys(values).forEach((key) => {
    if (!values[key] || values[key]?.length === 0) {
      errors[key] = requiredField;
      return;
    }
  });
  return errors;
}

export const submitHandler = async (values: unknown) => {
  try {
    const { description, bufferString, name, role, sidePose } = values as Profile;
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/save-profile`, {
      name,
      role,
      description,
    });
  
    const profileId = response.data.profileId;
    const thumbnailUrl = `${process.env.REACT_APP_BASE_URL}/save-profile-thumbnail/${profileId}`;
    const formatedString = bufferString.slice(23);
    await imageUploader(thumbnailUrl, formatedString);
  
    
    const sidePoseUrl = `${process.env.REACT_APP_BASE_URL}/save-profile-sidepose/${profileId}`;
    const compressedImage = await compressImage(sidePose.file, CompressionLevel.Medium);
    await imageUploader(sidePoseUrl, compressedImage);
  } catch(err) {
    console.log("Error uploading profile", err);
  }
}

export const submitUpdateHandler = async (values: unknown) => {
  try {
    const { profileId, description, bufferString, name, role, sidePose } = values as ProfileWithId;

    await axios.post(`${process.env.REACT_APP_BASE_URL}/update-profile`, {
      profileId,
      updatedFields: {
        name,
        role,
        description,
      },
    });

    if (bufferString) {
      const thumbnailUrl = `${process.env.REACT_APP_BASE_URL}/save-profile-thumbnail/${profileId}`;
      const formatedString = bufferString.slice(23);
      await imageUploader(thumbnailUrl, formatedString);
    }
  
    const sidePoseUrl = `${process.env.REACT_APP_BASE_URL}/save-profile-sidepose/${profileId}`;
    const compressedImage = await compressImage(sidePose.file, CompressionLevel.Medium);
    await imageUploader(sidePoseUrl, compressedImage);
  } catch (err) {
    console.log("Error updating profile", err);
  }
}

export const deleteHandler = async (id: string) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/remove-profile/${id}`);
  } catch(err) {
    console.log("Error deleting profile", err);
  }
}