import axios from "axios";
import { compressImage, CompressionLevel } from "../../utils/ImageCompressor";
import { imageUploader } from "../../utils/ImageUplader";
import { Project, ProjectWithId } from "./Interface";

export const validationHandler = (values: any) => {
  const errors: { [k: string]: any } = {};
  const requiredField = "Required";

  Object.keys(values).forEach((key) => {
    if (!values[key] || values[key]?.length === 0) {
      errors[key] = requiredField;
      return;
    }
  });
  return errors;
}

export const submitHandler = async (values: unknown) => {
  try {
    const { capability, description, images, location, projectType } = values as Project;
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/save-project`, {
      location,
      capability,
      projectType,
      description,
    });

    const projectId = response.data.projectId;
    const coverImage = images[0];
    const url = `${process.env.REACT_APP_BASE_URL}/save-project-thumbnail/${projectId}`;
    const compressedCoverImage = await compressImage(coverImage.file, CompressionLevel.High);
    await imageUploader(url, compressedCoverImage);

    (images.slice(1)).forEach(async (image) => {
      const compressedImage = await compressImage(image.file, CompressionLevel.Medium);
      const url = `${process.env.REACT_APP_BASE_URL}/save-project-image/${projectId}`;
      await imageUploader(url, compressedImage);
    });
  } catch(err) {
    console.log("Error uploading project", err);
  }
}

export const submitUpdateHandler = async (values: unknown, originalImages: unknown) => {
  try {
    const { projectId, capability, description, images, location, projectType } = values as ProjectWithId;
    await axios.post(`${process.env.REACT_APP_BASE_URL}/update-project`, {
      projectId,
      updatedFields: {
        location,
        capability,
        projectType,
        description,
      }
    });

    const coverImage = images[0];
    const orgImgs = originalImages as string[];
    if (orgImgs.length === 0 || coverImage.filePath !== orgImgs[0]) {
      const url = `${process.env.REACT_APP_BASE_URL}/save-project-thumbnail/${projectId}`;
      const compressedCoverImage = await compressImage(coverImage.file, CompressionLevel.High);
      await imageUploader(url, compressedCoverImage);
    }

    (images.slice(1)).forEach(async (image, index) => {
      if (orgImgs.length === 0 || image.filePath !== orgImgs[index + 1]) {
        const compressedImage = await compressImage(image.file, CompressionLevel.Medium);
        const url = `${process.env.REACT_APP_BASE_URL}/save-project-image/${projectId}`;
        await imageUploader(url, compressedImage);
      }
    });
  } catch(err) {
    console.log("Error updating project", err);
  }
}

export const deleteHandler = async (id: string) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/remove-project/${id}`);
  } catch(err) {
    console.log("Error deleting project", err);
  }
}