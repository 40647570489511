import React from "react";
import styles from "./PreviewProject.module.scss";
import { IProjectForm } from "./ProjectForm";
import parse from "html-react-parser";
import { ImageSlider } from "@maplinworld/ui-components";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useFormikContext } from "formik";

export const PreviewProject = () => {
  const { values } = useFormikContext<IProjectForm>();

  return (
    <div className={styles.container}>
      <div className={styles.slider}>
        <ImageSlider images={values.images.slice(0).map(image => image.filePath)} />
      </div>
      <p className={styles.location}>
        <LocationOnIcon className={styles.icon} />{values.location}
      </p>
      <p className={styles.projectType}>
        {`${values.capability}, ${values.projectType}`}
      </p>

      <div className={styles.description}>{(parse(values.description))}</div>
    </div>
  );
};
