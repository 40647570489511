import { Button, Typography } from '@mui/material';
import { Field, Form, useFormikContext } from "formik";
import { TextField } from 'formik-mui';
import { DescriptionBox } from "../DescriptionBox/DescrptionBox";
import { ImageCropper } from "../ImageCropper";
import addProfileStyle from "./AddProfile.module.scss";
import { UploadImage, Image, ImageType } from '../ImageItem';

export interface IProfileForm {
  name: string;
  role: string;
  description: string;
  image: string;
  sidePose: ImageType;
}

export const INITIAL_VALUE: IProfileForm = {
  name: "",
  role: "",
  description: "",
  image: "",
  sidePose: undefined as ImageType,
}

export const ProfileForm = () => {
  const { values, isSubmitting, setFieldValue, submitForm } = useFormikContext<IProfileForm>();

  return (
    <Form className={addProfileStyle.form}>
      <Typography>Thumbnail</Typography>
      <ImageCropper />
      <Typography>Side pose</Typography>
      <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => {
        if (e.target.files && e.target.files[0]) {
          const file = e.target.files[0];
          const imageFile: Image = {
            fileName: file.name,
            filePath: URL.createObjectURL(file),
            file
          }
          setFieldValue("sidePose", imageFile);
        }
      }} />
      {values.sidePose !== undefined && (<UploadImage image={values.sidePose} />)}
      <Field
        className={`${addProfileStyle.spacing}`}
        component={TextField}
        value={values.name}
        name="name"
        label="Name"
        variant="outlined"
        size="small"
        fullWidth
      />
      <Field
        className={`${addProfileStyle.spacing}`}
        component={TextField}
        value={values.role}
        name="role"
        label="Role"
        variant="outlined"
        size="small"
        fullWidth
      />
      <DescriptionBox name={"description"} value={values.description} />
      <Button
        className={addProfileStyle.spacing}
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={submitForm}
      >
        Post
      </Button>
    </Form>
  );
};
