import React from "react";
import styles from "./PreviewProfile.module.scss";
import { IProfileForm } from "./ProfileForm";
import parse from "html-react-parser"
import { Image } from "../ImageItem";
import { useFormikContext } from "formik";

export const PreviewProfile = () => {
  const { values } = useFormikContext<IProfileForm>();

  return (
    <div className={styles.container}>
      <div className={styles.basicInfo}>
        {!values.image ? <></> : <img src={values.image} alt="profile" />}
        <p className={styles.role}>{values.role}</p>
        <p className={styles.name}>{values.name}</p>
      </div>
      <div className={styles.about}>
        {!values.name ? <></> : <p className={styles.title}>About {values.name.split(" ")[0]}</p>}
        <div className={styles.splitSection}>
          <div className={styles.content}>{(parse(values.description))}</div>
          <img src={!values.sidePose ? "" : (values.sidePose as Image).filePath} alt="side pose" />
        </div>
      </div>
    </div>
  );
};
