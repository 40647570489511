import { useIsAuthenticated } from "@azure/msal-react";
import React, { useCallback, useState } from 'react';
import { IUser, ProfileLoader } from "./components/ProfileLoader";
import { Login } from "./pages/Login/Login";
import { Header } from "./components/Header";
import { Home } from "./pages/home/Home";

export function App() {
  const isAuthenticated = useIsAuthenticated();
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const onProfileLoad = useCallback((profile: IUser) => {
    setUser(profile);
  }, []);
  return (
    isAuthenticated ?
      <ProfileLoader onProfileLoaded={onProfileLoad}>
        {user &&
          <>
            <Header userName={user.displayName} />
            <Home />
          </>}
      </ProfileLoader>
      :
      <Login />
  );
}