import React from "react";
import { IEditMode } from "../../Types";
import { IProjectForm, ProjectForm } from "./ProjectForm";
import { useFormikContext } from "formik";
import axios from "axios";
import { FullScreenDialog } from "../FullScreenDialog/FullScreenDialog";
import { FormPageLayout } from "../FormPageLayout/FormPageLayout";
import { PreviewProject } from "./PreviewProject";
import { withFormik } from "../../utils/Helpers";
import { Image } from "../ImageItem";

export interface EditProjectProps {
  initialValues: IProjectForm;
  submitHandler: (values: unknown) => Promise<void>;
  validationHandler: (values: unknown) => unknown;
  originalProjectRef: React.MutableRefObject<IProjectForm | undefined>;
  editMode: IEditMode;
  onClose: () => void;
}

const EditProjectMain = (props: EditProjectProps) => {
  const { setValues } = useFormikContext<IProjectForm>();
  
  React.useEffect(() => {
    if (!props.editMode.id)
      return;
    
    axios.get(`${process.env.REACT_APP_BASE_URL}/project/${props.editMode.id}`)
    .then((response) => {
      if (response.data) {
        const formData = response.data;
        props.originalProjectRef.current = formData;
        setValues({
          location: formData.location,
          capability: formData.capability,
          projectType: formData.projectType,
          description: formData.description,
          images: formData.images.map((imgPath: string) => ({ filePath: imgPath } as Image)),
        });
      }
    }).catch(() => {
      console.log("Error fetching project with id", props.editMode.id);
    });
  }, [props.editMode, props.originalProjectRef, setValues]);

  return (
    <FullScreenDialog handleClose={props.onClose} open={props.editMode.isOpen} title={"Update Project"}>
      <FormPageLayout
        formComponent={<ProjectForm />}
        previewComponent={<PreviewProject />} />
    </FullScreenDialog >
  );
};

export const EditProject = withFormik(EditProjectMain);
