
import Compress from "compress.js";

export enum CompressionLevel {
  Low,
  Medium,
  High
}
type CompressionConfiguration = {
  [K in CompressionLevel]: {
    quality: number,
    maxWidth: number,
    maxHeight: number,
  }
}
const COMPRESSION_CONFIGURATION: CompressionConfiguration = {
  [CompressionLevel.High]: {
    quality: 0.70,
    maxHeight: 512,
    maxWidth: 512,
  },
  [CompressionLevel.Medium]: {
    quality: 0.80,
    maxHeight: 1024,
    maxWidth: 1024,
  },
  [CompressionLevel.Low]: {
    quality: 0.90,
    maxHeight: 1920,
    maxWidth: 1920,
  }
}

export const compressImage = async (file: File, compressionLevel: CompressionLevel): Promise<string> => {
  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    size: 1, // the max size in MB, defaults to 2MB
    ...COMPRESSION_CONFIGURATION[compressionLevel],
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  return resizedImage[0].data;
}