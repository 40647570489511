export const drawerTabs = [
  "Project",
  "Blog",
  "Profile",
  "Quotation"
];

export const horizontalTabs = [
  "All",
  "UnApproved",
  "Approved"
];
