import { Typography } from "@mui/material";
import { Button, MenuItem } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import { Select, TextField } from 'formik-mui';
import { DescriptionBox } from '../DescriptionBox/DescrptionBox';
import { Image } from "../ImageItem";
import { UploadImages } from '../UploadImages';
import { capability, projectType } from './Interface';
import addProjectStyles from "./AddProject.module.scss";

export interface IProjectForm {
  location: string;
  capability: string;
  projectType: string;
  description: string;
  images: Image[];
}

export const INITIAL_VALUE: IProjectForm = {
  location: "",
  capability: capability[0],
  projectType: "",
  description: "",
  images: [] as Image[],
}

export const ProjectForm = () => {
  const { values, isSubmitting, submitForm } = useFormikContext<IProjectForm>();
  
  return (
    <Form className={addProjectStyles.form}>
      <Typography className={addProjectStyles.spacing}>*Note first image will be used as thumbnail</Typography>
      <UploadImages />
      <Field
        className={addProjectStyles.spacing}
        component={TextField}
        name="location"
        value={values.location}
        label="Location"
        variant="outlined"
        size="small"
        fullWidth
      />
      <br />
      <Field
        className={`${addProjectStyles.spacing} ${addProjectStyles.select}`}
        component={Select}
        id="capability"
        name="capability"
        value={values.capability}
        labelId="capability"
        fullWidth={true}
        size="small"
        label="Capability">
        {capability.map(value => {
          return <MenuItem value={value}>{value}</MenuItem>
        })}
      </Field>
      <br />
      <Field
        className={`${addProjectStyles.spacing} ${addProjectStyles.select}`}
        component={Select}
        id="projectType"
        name="projectType"
        value={values.projectType}
        labelId="projectType"
        fullWidth={true}
        size="small"
        label="Project Type">
        {projectType.map(value => {
          return <MenuItem value={value}>{value}</MenuItem>
        })}
      </Field>
      <br />
      <DescriptionBox name={"description"} value={values.description} />
      <br />
      <Button
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={submitForm}
      >
        Post
      </Button>
    </Form>
  );
};
