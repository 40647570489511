import * as React from "react";
import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import fullScreenDialogStyles from "./FullScreenDialog.module.scss";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface IFullScreenDialog {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode
}
export const FullScreenDialog = ({ open, handleClose, title, children }: IFullScreenDialog) => {

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={fullScreenDialogStyles.dialog}
    >
      <AppBar className={fullScreenDialogStyles.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography className={fullScreenDialogStyles.title} variant="h6" component="div">
            {title}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <div>
        {children}
      </div>
    </Dialog>
  );
}