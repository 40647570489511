import { Table as MuiTable, TableContainer, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import React from "react";
import styles from "./Table.module.scss";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const imageRegex = /\.(jpg|png|jpeg)$/i;

export interface TableProps {
  columns: string[];
  data: string[][];
  isEditable?: boolean;
  onEditClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
}

export const Table = (props: TableProps) => {
  return (
    <TableContainer className={styles.tableContainer}>
      <MuiTable className={styles.table}>
        <TableHead className={styles.tableHead}>
          <TableRow>
            {props.isEditable && <TableCell></TableCell>}
            {props.columns.map((column) => <TableCell>{column}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>
          {props.data.map((row: string[]) => {
            return <TableRow className={styles.tableRow}>
              {props.isEditable && <TableCell><EditIcon onClick={() => props.onEditClick!(row[0])} className={styles.editIcon} /> / <DeleteIcon onClick={() => props.onDeleteClick!(row[0])} className={styles.editIcon} /></TableCell>}
              {row.map((column: string, index: number) => {
                // index 0 is id
                if (index === 0) return <></>;

                return <TableCell>{imageRegex.test(column) ? <img className={styles.img} src={column} alt="" /> : column}</TableCell>;
              })}
            </TableRow>
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
